<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import type { HeroWhiteOverlayStoryblok } from '~/types/storyblok'
import { blokLink } from '~/helpers/blokLink'
import InfoBar from '~/components/Global/InfoBar.vue'

const props = defineProps<{
  blok: HeroWhiteOverlayStoryblok
}>()

const heroHeight = {
  small: 'lg:min-h-[400px]',
  medium: 'lg:min-h-[600px]',
  large: 'lg:min-h-[800px]',
  '': 'lg:min-h-[400px]',
}

const headingTag = useStoryblokUi().headingTag(props.blok.heading_tag)

const backgroundStyle = computed(() => {
  if (props.blok.background_image?.filename) {
    return {
      backgroundImage: `url('${props.blok.background_image.filename}')`,
    }
  }
})
</script>

<template>
  <div
    v-editable="blok"
    :style="backgroundStyle"
    class="bg-cover bg-left sm:rounded-lg"
    :class="[heroHeight[props.blok.hero_height]]">
    <InfoBar
      v-if="blok.show_info_bar && blok.background_color"
      :text-color="blok.text_color!"
      :background-color="blok.background_color"
      :info-text="blok.info_text!" />

    <div class="flex break-inside-avoid items-center justify-end py-40">
      <NuxtLink
        v-if="blok.button_to"
        :to="blokLink(blok.button_to)"
        class="group inline-flex max-w-[90%] items-center justify-center rounded-l-xl bg-white/80 px-8 py-6 transition-colors hover:bg-white/90 lg:max-w-[80%]">
        <span class="mr-6 lg:mr-4">
          <component :is="headingTag" class="text-xl font-semibold">{{ blok.heading }}</component>
          <span v-if="blok.text" class="block">{{ blok.text }}</span>
        </span>
        <ChevronRightIcon
          class="max-h-[32px] min-h-[32px] min-w-[32px] max-w-[32px] transition-transform duration-300 ease-out group-hover:translate-x-1" />
      </NuxtLink>
    </div>
  </div>
</template>
